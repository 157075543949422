<template>
  <div class="jump">
    <!-- <p style="24px">
      正在跳转到支付页面，请稍候~
    </p> -->
    <img src="@/assets/images/loading.gif" alt="" />
  </div>
</template>

<script>
// import {getRedirectUrl} from '@/api/api';
// import config from "@/config";
// import getPayWayCompany from "@/utils/wayCode";
import wayCodeUtils from '@/utils/wayCode'

// 分发器页面，  用户扫码后进入的第一个页面：
// 判断是否存在channelUserId, 如果存在则直接跳到 对应的支付页面，如果不存在则重定向到对应的页面。
export default {
  data: function (){
      return {
          appId: '',
          channelUserId: '',
      }
  },
  components: {
  },

  mounted() {
      this.appId = this.$route.params.jeepayToken
      this.channelUserId = this.$route.query.channelUserId
      this.groupId = this.$route.query.groupId
    console.log("正在跳转", this.$router.params)
      // alert(this.appId)
      // alert(this.channelUserId)
      // {wayCode: "ALI_JSAPI", routeName: "CashierAlipay"}
      // {wayCode: "WX_JSAPI", routeName: "CashierWxpay"}
      // this.$router.push({name: "AliPayCompany", params: {appId: this.appId}})
      // this.$router.push({name: "WxPayCompany", params: {appId: this.appId}})
      // this.$router.push({name: "WxPayCompanyJump", params: {appId: this.appId, channelUserId: this.channelUserId}})

      let payWayCompany = wayCodeUtils.getPayWayCompany()

      if(payWayCompany === null){
          // alert('https://qr.95516.com/qrcGtwWeb-web/api/userAuth?version=1.0.0&redirectUrl=' + encodeURIComponent('https://payment.sybpay.cn/#/hubCompany/660ae169e4b0db0649ddaa4e'))
          window.location.href = 'https://qr.95516.com/qrcGtwWeb-web/api/userAuth?version=1.0.0&redirectUrl=' + encodeURIComponent('https://payment.sybpay.cn/#/hubCompany/660ae169e4b0db0649ddaa4e')
      } else {
          if(payWayCompany.wayCode === 'ALI_JSAPI') {
              this.$router.push({name: payWayCompany.routeName, params: {appId: this.appId, channelUserId: this.channelUserId, groupId: this.groupId}})
          }

          if(payWayCompany.wayCode === 'WX_JSAPI') {
              if(!this.channelUserId || this.channelUserId === ''){
                  this.$router.push({name: payWayCompany.routeJumpName, params: {appId: this.appId, channelUserId: this.channelUserId, groupId: this.groupId}})
              } else {
                  this.$router.push({name: payWayCompany.routeName, params: {appId: this.appId, channelUserId: this.channelUserId, groupId: this.groupId}})
              }
          }
      }


    // eslint-disable-next-line no-constant-condition
    // if( true ){
    //   this.$router.push({name: "CashierAlipay"})
    //   return;
    // }


    //TODO 需要获取到不同的商户的userId
    // if(channelUserId.getChannelUserId()){// 存在
    //
    //   //重定向到对应的支付页面
    //   this.$router.push({name: wayCodeUtils.getPayWay().routeName})
    //   return ;
    //
    // }

    // const that = this;
    //以下为不存在
    /*getRedirectUrl().then(res => {
      location.href = res;
    }).catch(res => {
      that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
    });*/

  }


}
</script>
<style lang="css" scoped>
  .jump {
    height:100vh;
    width: 100vw;
    display:flex;
    justify-content: center;
    align-items: center;
  }
</style>
